// ** React Imports
import { useState, createContext, useEffect } from "react";
import { useRTL } from "@hooks/useRTL";
// ** Intl Provider Import
import { IntlProvider } from "react-intl";

// ** Core Language Data
import messagesEn from "@assets/data/locales/en.json";
import messagesDe from "@assets/data/locales/de.json";
import messagesFr from "@assets/data/locales/fr.json";
import messagesPt from "@assets/data/locales/pt.json";
import messagesSa from "@assets/data/locales/ar.json";

// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesDe from "@src/assets/data/locales/de.json";
import userMessagesFr from "@src/assets/data/locales/fr.json";
import userMessagesPt from "@src/assets/data/locales/pt.json";
import userMessagesSa from "@src/assets/data/locales/sa.json";

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt },
  ar: { ...messagesSa, ...userMessagesSa },
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang").replace('^"|"$', "")
      : "en"
  );
  const [messages, setMessages] = useState(
    menuMessages[
      localStorage.getItem("lang")
        ? localStorage.getItem("lang").replace('^"|"$', "")
        : "en"
    ]
  );
  const [isRtl, setIsRtl] = useRTL();
  // ** Switches Language
  const switchLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    if (lang === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };

  useEffect(async () => {
    if (
      localStorage.getItem("lang") &&
      localStorage.getItem("lang").replace('^"|"$', "") === "ar"
    ) {
      setIsRtl(true);
    }
  }, []);

  return (
    <Context.Provider value={{ locale, switchLanguage, isRtl }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
