import mock from '../mock'
/* eslint-disable */
import { paginateArray, sortCompare, randomDate, getRandomInt } from '../utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

const data = {
  products: [
    // {
    //   id: 1,
    //   name: 'Digital Menu',
    //   slug: '3-year-unlimited-cloud-storage-service-activation-card-other-1',
    //   description: 'Use the digital list to create endless categories, and add/edit/update products that will automatically sync to the digital list.',
    //   brand: 'VicTsing',
    //   price: 294,
    //   productCode: 'ellmenu',
    //   image: require('@src/assets/images/pages/eCommerce/ellmenu.jpeg').default,
    //   hasFreeShipping: true,
    //   hasPackage: true,
    //   package: [{id:'GIUFTll76p', name:'6 month package', price: '294.00'}],
    //   features: [
    //       {
    //         name: 'Categories and Products',
    //         description: 'You can add and edit  countless multiple categories and products, add names, images and details for each product, and enable/disable/update products as they become available.'
    //       },
    //       {
    //         name: 'Product Sizes',
    //         description:'You can add different product sizes (eg Small/Medium/Family Pizza) or allow only one plate size. and setting separate prices for each size.',
    //       },
    //       {
    //         name: 'Special Offers',
    //         description: 'You can increase your sales and win customers by launching special promotions for the number of people, and seasonal offers at any time you want.'
    //       },
    //       {
    //         name: 'Flexible and diversified performance',
    //         description: 'Fast browsing in the front-end for customers and mobile-friendly, the digital menu can be viewed across different devices (computers, smartphones and tablets).'
    //       },
    //       {
    //         name: 'Multiple Features',
    //         description: 'You can diversify between the features of the digital menu for your customers with 10 different colors!'
    //       },
    //       {
    //         name: 'Multiple languages',
    //         description: 'The digital menu can be easily translated and made available to your customers in several different languages.'
    //       }
    //   ],
    //   rating: 2
    // },
    {
      id: 2,
      enc_id:'FmixaFi7CTyRIG2DLB15',
      name: 'Smart Tent - Restaurant',
      slug: 'smart-tent-restaurant-2',
      description: 'The smart tent contains an Arabic destination and an English destination, and is equipped with two communication technologies<br/>1. Near Field Communication.<br/>2. QR Code.<br/>The two technologies are programmed into your digital menu to give your customers access to the product menu with a nice touch. As a community service and a healthy lifestyle, the smart tent was provided with a quick response code for the awareness platform of the Ministry of Health.',
      brand: 'ellMenus',
      productCode: 'Smart tent R',
      price: 10,
      image: require('@src/assets/images/pages/eCommerce/smart-tent-r.jpeg').default,
      hasFreeShipping: false,
      hasPackage: false,
      package: [],
      features: [],
      rating: 5
    },
    {
      id: 3,
      enc_id:'Nxj5KlJOsB4L7UjcgyIH',
      name: 'Smart Tent - Enough',
      slug: 'smart-tent-enough-3',
      description: 'The smart tent contains an Arabic destination and an English destination, and is equipped with two communication technologies<br/>1. Near Field Communication.<br/>2. QR Code.<br/>The two technologies are programmed into your digital menu to give your customers access to the product menu with a nice touch. As a community service and a healthy lifestyle, the smart tent was provided with a quick response code for the awareness platform of the Ministry of Health.',
      brand: 'ellMenus',
      productCode: 'Smart tent C',
      price: 10,
      image: require('@src/assets/images/pages/eCommerce/smart-tent-c.jpeg').default,
      hasFreeShipping: false,
      hasPackage: false,
      package: [],
      features: [],
      rating: 5
    },
    {
      id: 4,
      enc_id:'NhXe9WAeplQpSCyd1K46',
      name: 'Ad holder',
      slug: 'ad-holder-4',
      description: 'L-shaped vertical advertising stand including printing price + NFC technology',
      productCode: 'AK',
      brand: 'ellMenus',
      price: 19,
      image: require('@src/assets/images/pages/eCommerce/ak.jpeg').default,
      hasFreeShipping: false,
      hasPackage: false,
      package: [],
      features: [],
      rating: 2
    },
    {
      id: 5,
      enc_id:'Ow5Lpbr061AIg8qnN35D',
      name: 'Special Design',
      slug: 'special-design-5',
      description: '',
      productCode: 'Design',
      brand: 'ellMenus',
      price: 99,
      image: require('@src/assets/images/pages/eCommerce/special-design.png').default,
      hasFreeShipping: false,
      hasPackage: false,
      package: [],
      features: [],
      rating: 2
    },
    {
      id: 6,
      enc_id:'HybUuWGNEM9UIOJJnfJj',
      name: 'Entering menu data',
      slug: 'entering-menu-data-6',
      description: '',
      productCode: 'Entry menu',
      brand: 'ellMenus',
      price: 69,
      image: require('@src/assets/images/pages/eCommerce/entry-menu.jpeg').default,
      hasFreeShipping: false,
      hasPackage: true,
      package: [{id:"ZT0zXdSiac", name: '50 products or less | 69 riyals', price: '69.00'}, {id:'FMkMKW2Twb', name:'50 products and more | 129 riyals', price: '129.00'}],
      features: [],
      rating: 4
    },
    {
      id: 7,
      enc_id:'Ow5Lpbr0EM9UIOJJnfJj',
      name: 'SMS Topup',
      slug: 'sms-toppup-7',
      description: '',
      productCode: 'SMS Pack',
      brand: 'ellMenus',
      // price: 69,
      image: require('@src/assets/images/pages/eCommerce/sms-topup.png').default,
      hasFreeShipping: false,
      hasPackage: true,
      package: [],
      features: [],
      rating: 4
    }
  ],
  userWishlist: [],
  userCart: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/products').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', sortBy = 'featured', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.products.filter(product => product.name.toLowerCase().includes(queryLowered))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'price'
    }
    if (sortBy === 'price-asc') {
      return 'price'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
    /* eslint-enable */
  })

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/ecommerce\/products\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(p => p.id === productId)
  const product = data.products[productIndex]

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

    // * Add Dummy data for details page
    product.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']

    return [200, { product }]
  }
  return [404]
})

// ------------------------------------------------
// GET: Return Wishlist Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/wishlist').reply(() => {
  const products = data.userWishlist.map(wishlistProduct => {
    const product = data.products.find(p => p.id === wishlistProduct.productId)
    product.isInCart = data.userCart.findIndex(p => p.productId === wishlistProduct.productId) > -1
    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// GET: Return Cart Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/cart').reply(() => {
  const products = data.userCart.map(cartProduct => {
    const product = data.products.find(p => p.id === cartProduct.productId)

    // Other data
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
    product.qty = cartProduct.qty
    product.pid = cartProduct.pid ? cartProduct.pid : ''
    product.sid = cartProduct.sid ? cartProduct.sid : ''
    product.shippingDate = randomDate(nextDay, nextWeek)
    product.offers = getRandomInt(1, 4)
    product.discountPercentage = getRandomInt(3, 20)

    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// POST: Add Item in user Cart
// ------------------------------------------------
mock.onPost('/apps/ecommerce/cart').reply(config => {
  // Get product from post data
  const { productId, qty, pid, sid } = JSON.parse(config.data)

  const { length } = data.userCart
  let lastId = 0
  if (length) lastId = data.userCart[length - 1].id

  data.userCart.push({
    id: lastId + 1,
    productId,
    qty,
    pid,
    sid
  })
  const products = data.userCart.map(cartProduct => {
    const product = data.products.find(p => p.id === cartProduct.productId)

    // Other data
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
    product.qty = cartProduct.qty
    product.pid = cartProduct.pid ? cartProduct.pid : ''
    product.sid = cartProduct.sid ? cartProduct.sid : ''
    product.shippingDate = randomDate(nextDay, nextWeek)
    product.offers = getRandomInt(1, 4)
    product.discountPercentage = getRandomInt(3, 20)

    return product
  })
  localStorage.setItem('cartItems', JSON.stringify(data.userCart));
  return [
    201,
    { products }
  ]
})


// ------------------------------------------------
// POST: Edit Item in user Cart
// ------------------------------------------------
mock.onPost('/apps/ecommerce/edit').reply(config => {
  // Get product from post data
  const { productId, qty, pid, sid } = JSON.parse(config.data)
  // let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  // productId = Number(productId)

  const productIndex = data.userCart.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userCart.splice(productIndex, 1)
  // return [200]
  const { length } = data.userCart
  let lastId = 0
  if (length) lastId = data.userCart[length - 1].id

  data.userCart.push({
    id: lastId + 1,
    productId,
    qty,
    pid,
    sid
  })
  localStorage.setItem('cartItems', JSON.stringify(data.userCart));
  const products = data.userCart.map(cartProduct => {
    const product = data.products.find(p => p.id === cartProduct.productId)

    // Other data
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
    product.qty = cartProduct.qty
    product.pid = cartProduct.pid ? cartProduct.pid : ''
    product.sid = cartProduct.sid ? cartProduct.sid : ''
    product.shippingDate = randomDate(nextDay, nextWeek)
    product.offers = getRandomInt(1, 4)
    product.discountPercentage = getRandomInt(3, 20)

    return product
  })
  return [
    201,
    { products }
  ]
})

// ------------------------------------------------
// DELETE: Remove Item from user Cart
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/cart\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userCart.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userCart.splice(productIndex, 1)
  localStorage.setItem('cartItems', JSON.stringify(data.userCart));
  return [200]
})

// ------------------------------------------------
// POST: Add Item in user Wishlist
// ------------------------------------------------
mock.onPost('/apps/ecommerce/wishlist').reply(config => {
  // Get product from post data
  const { productId } = JSON.parse(config.data)

  const { length } = data.userWishlist
  let lastId = 0
  if (length) lastId = data.userWishlist[length - 1].i

  data.userWishlist.push({
    id: lastId + 1,
    productId: Number(productId)
  })

  return [201]
})

mock.onPost('/apps/ecommerce/deleteall').reply(config => {
  // Get product from post data
  data.userCart = []
  localStorage.removeItem('cartItems');
  return [200]
})

// ------------------------------------------------
// DELETE: Remove Item from user Wishlist
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/wishlist\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userWishlist.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userWishlist.splice(productIndex, 1)

  return [200]
})
