// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  layoutSidebar: [],
  userAccess: [],
  branchFormFields: [],
  layoutProfileSidebar: null,
  profileStrength: null,
  salesSummary: [],
  apiRoute: {
    apiLocalhost: "http://localhost:3000",
    apiMerchantDev: "http://www.merchant.ellmenus.dev",
    apiMerchantCom: "http://www.merchant.ellmenus.com",
    baseApiURL: "",
  },
  profile: null,
  settings: null,
  update_bell: "",
  loading: false,
  darkMode: '"light"',
  loadingMain: false,
  refreshOrderChartItem: false,
  userDropdown: false,
  ordersDropdown: "",
  userSidebar: false,
  isUserBranch: localStorage.getItem('branch_id'),
  moyasarPaymentGateway: {
    id:"",
    amount:"",
    description:"",
  }
};
export const checkUserBranch = (data) => {
  if (localStorage.getItem('branch_id')) {
    return true
  }
  return data && data.parent_enc_id
}
const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_CONTENT_WIDTH":
      return { ...state, contentWidth: action.value };
    case "HANDLE_MENU_COLLAPSED":
      window.localStorage.setItem("menuCollapsed", action.value);
      return { ...state, menuCollapsed: action.value };
    case "HANDLE_MENU_HIDDEN":
      return { ...state, menuHidden: action.value };
    case "HANDLE_RTL":
      return { ...state, isRTL: action.value };
    case "GET_LAYOUT_SIDEBAR_DATA":
      const tempDataSidebar = [...action.value.items];
      // tempDataSidebar.push({
      //   visible: true,
      //   tag: "pushNotification",
      //   label: "OneSignal",
      //   url: "/push-notifications",
      // });
      // tempDataSidebar.push({
      //   visible: true,
      //   tag: "merchantUserStaff",
      //   label: "Staff Members",
      //   url: "/merchant-staff",
      // });

      // tempDataSidebar.push({
      //   visible: true,
      //   tag: "merchantTable",
      //   label: "Dining Table",
      //   url: "/merchant-table",
      // });
      // const tempAccess = [...action.value.merchant_info.user_access, "ell_allergen_access", "ell_gallery_access", "ell_social_icons", "ell_nutrient_access", "ell_label_access", "ell_payment_gateways"]
      return {
        ...state,
        layoutSidebar: tempDataSidebar,
        layoutProfileSidebar: action.value,
        isUserBranch: checkUserBranch(action.value.merchant_info),
        userAccess: action.value.merchant_info.user_access,
      };
    case "SET_PROFILE_STRENGTH":
      return { ...state, profileStrength: action.value };
    case "GET_SALES_SUMMARY_DATA":
      return { ...state, salesSummary: action.value };
    case "OPEN_USER_DROPDOWN":
      return { ...state, userDropdown: action.value };
    case "OPEN_MOYASAR_PAYMENT_GATEWAY":
      return { ...state, moyasarPaymentGateway: action.value };
    case "OPEN_ORDERS_DROPDOWN":
      return { ...state, ordersDropdown: action.value };
    case "OPEN_USER_SIDEBAR":
      return { ...state, userSidebar: action.value };
    case "SET_DARK_MODE":
      return { ...state, darkMode: action.value };
    case "ADD_BRANCH_FIELD":
      const toBeAdd = [];
      [...Array(action.value).keys()].map((item, i) => {
        toBeAdd.push({});
      });
      return {
        ...state,
        branchFormFields: [...state.branchFormFields, ...toBeAdd]
      }
    case "SET_BRANCH_FIELD":
      const newArray = state.branchFormFields.map((item, i) => {
        if (action.value.index === i) {
          return { ...item, [action.value.name]: action.value.value };
        } else {
          return item;
        }
      });
      return {
        ...state,
        branchFormFields: newArray
      }
    case "REMOVE_BRANCH_FIELD":
      return {
        ...state,
        branchFormFields:  [
          ...state.branchFormFields.slice(0, action.value),
          ...state.branchFormFields.slice(action.value + 1)
        ]
      }
    case "UPDATE_PROFILE":
      return {
        ...state,
        layoutProfileSidebar: {
          ...state.layoutProfileSidebar,
          merchant_info: {
            ...state.layoutProfileSidebar.merchant_info,
            logo: action.value,
          },
        },
      };
    case "GET_PROFILE_DATA":
      return { ...state, profile: action.value };
    case "GET_SETTINGS_DATA":
      return {
        ...state,
        settings: action.value,
        layoutProfileSidebar: {
          ...state.layoutProfileSidebar,
          currency_details: action.value.currency_details,
        },
      };
    case "SET_CURRENCY_DETAILS":
      return {
        ...state,
        settings: action.value,
        layoutProfileSidebar: {
          ...state.layoutProfileSidebar,
          currency_details: action.value,
        },
      };
    case "SET_UPDATE_BELL":
      return { ...state, update_bell: action.value };
    case "UPDATE_REFRESH_ORDER_CHART":
      return { ...state, refreshOrderChartItem: action.value };
    case "SET_BASE_URL":
      return {
        ...state,
        baseApiURL: action.value ? action.value.baseApiURL : "",
        baseURL: action.value ? action.value.baseURL : "",
        instanceIdPushNotify: action.value
          ? action.value.instanceIdPushNotify
          : "",
        telegramKey: action.value ? action.value.telegramKey : "",
      };
    case "SET_API_URL":
      return {
        ...state,
        apiRoute: { ...state.apiRoute, baseApiURL: action.value },
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "SET_LOADING_MAIN":
      return {
        ...state,
        loadingMain: action.value,
      };
    default:
      return state;
  }
};

export default layoutReducer;
