// **  Initial State
const initialState = {
  userData: {},
  formFlowData: null,
  cacheFormData: null,
  tokenId: null,
  orderId: null,
  payment_info: null,
  product_package: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName],
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, ...obj };
    case "SET_FORM_FLOW_DATA":
      return { ...state, formFlowData: action.value };
    case "SET_PAYMENT_INFO":
      return { ...state, payment_info: action.value };
    case "CACHE_PAYMENT_DATA":
      return { ...state, product_package: action.value };

    case "CACHE_SIGN_UP_DATA":
      return {
        ...state,
        cacheFormData: action.value,
      };
    case "SET_UP_PAYMENT":
      return {
        ...state,
        tokenId: action.value.id,
        orderId: action.value.order_id,
      };
    case "SET_UP_PACKAGE":
      return {
        ...state,
        packageId: action.value,
      };
    case "SET_UP_PAYMENT_PRICE":
      return {
        ...state,
        amount: action.value,
      };
    case "CLEAN_CACHE_SIGN_UP_DATA":
      return {
        ...state,
        cacheFormData: null,
      };
    case "CLEAR_SET_UP_PAYMENT":
      return {
        ...state,
        tokenId: null,
        orderId: null,
      };
    default:
      return state;
  }
};

export default authReducer;
