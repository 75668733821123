import axios from "axios";

// ** Handles Layout Content Width (full / boxed)
export const handleContentWidth = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_CONTENT_WIDTH", value });

// ** Handles Base URL path
export const handleBaseUrlPath = (value) => (dispatch) => {
  dispatch({ type: "SET_BASE_URL", value });
};

// ** Handles Menu Collapsed State (Bool)
export const handleMenuCollapsed = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_MENU_COLLAPSED", value });

// ** Handles Menu Hidden State (Bool)
export const handleMenuHidden = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_MENU_HIDDEN", value });

// ** Handles Update Bell State (Bool)
export const handleUpdateBell = (value) => (dispatch) =>
  dispatch({ type: "SET_UPDATE_BELL", value });

// ** Handles RTL (Bool)
export const handleRTL = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_RTL", value });

// ** Get Layout sidebar data from server
export const getLayoutSidebarData = () => {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_BASE_API_URL + `/api/v1/merchant/auth-data?ver=${Date.now()}`)
      .then((response) => {
        if (response && response.data && response.data.response) {
          if (response.data.response.notification_sound_selected) {
            localStorage.setItem(
              "notify_sound",
              response.data.response.notification_sound_selected
            );
          }

          dispatch({
            type: "GET_LAYOUT_SIDEBAR_DATA",
            value: response.data.response,
          });
          localStorage.setItem(
            "profileLogo",
            response.data.response.merchant_info.logo
          );
          setTimeout(() => {
            const url = window.location.pathname;
            const el = document.querySelectorAll('[href="' + url + '"]');
            if (el.length && el[0].closest('.nav-item.has-sub')) {
              el[0].closest('.collapse').classList.add('show');
              el[0].closest('.nav-item.has-sub').classList.add('open');
              el[0].closest('.nav-item.has-sub').classList.add('sidebar-group-active');
              if (el[0].closest('.nav-item.has-sub').parentElement.closest('.nav-item.has-sub')) {
                el[0].closest('.collapse').parentElement.closest('.collapse').classList.add('show');
                el[0].closest('.nav-item.has-sub').parentElement.closest('.nav-item.has-sub').classList.add('open');
                el[0].closest('.nav-item.has-sub').parentElement.closest('.nav-item.has-sub').classList.add('sidebar-group-active');
              }
            }
          }, 1500)
          axios
              .get(process.env.REACT_APP_BASE_API_URL + `/api/v1/profile/strength`)
              .then((response) => {
                if (response.data && response.data.response) {
                  dispatch({
                    type: "SET_PROFILE_STRENGTH",
                    value: response.data.response,
                  });
                }
              });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};

// ** Get Sales Summary data from server
export const getSalesSummaryData = () => {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/v1/reports/sales-summary")
      .then((response) => {
        dispatch({
          type: "GET_SALES_SUMMARY_DATA",
          value: response.data.response.list,
        });
      });
  };
};

// ** Get Profile Data
export const getUserProfileData = (isMobile) => {
  return (dispatch) => {
    if (isMobile) {
      const payload = {
        device_type: "mobile",
      };
      return axios
        .post(
            process.env.REACT_APP_BASE_API_URL + "/api/v1/profile/view",
          payload
        )
        .then((response) => {
          if (response.data.response) {
            dispatch({
              type: "GET_PROFILE_DATA",
              value: response.data.response,
            });
          }
        });
    } else {
      return axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/v1/profile/view")
        .then((response) => {
          if (response.data.response) {
            dispatch({
              type: "GET_PROFILE_DATA",
              value: response.data.response,
            });
          }
        });
    }
  };
};

// ** Get User Settings Data
export const getUserSettingsData = () => {
  return async (dispatch) => {
    dispatch({ type: "SET_TABLE_LOADING", value: true });
    await axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/settings/update`, {
        id: "form-data",
      })
      .then((response) => {
        localStorage.setItem(
          "notify_sound",
          response.data.response
            ? response.data.response.notification_sound_selected
            : null
        );

        dispatch({
          type: "GET_SETTINGS_DATA",
          value: response.data.response,
        });
      });
    dispatch({ type: "SET_TABLE_LOADING", value: false });
  };
};

// set currency details
export const setCurrencyDetails = (values) => {
  return (dispatch) => {
    return dispatch({
      type: "SET_CURRENCY_DETAILS",
      value: values,
    });
  };
};

// ** Set API url
export const setApiURL = (value) => (dispatch) => {
  dispatch({ type: "SET_API_URL", value });
};

// set loading
export const setLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", value });
  };
};

export const setLoadingMain = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_MAIN", value });
  };
};

export const updateUserProfile = (value) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_PROFILE", value });
  };
};

export const setDarkMode = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_DARK_MODE", value });
  };
};

export const updateRefreshOrderChart = (value) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_REFRESH_ORDER_CHART", value });
  };
};

export const openUserDropdown = (value) => {
  return (dispatch) => {
    dispatch({ type: "OPEN_USER_DROPDOWN", value });
  };
};

export const openUserSidebar = (value) => {
  return (dispatch) => {
    dispatch({ type: "OPEN_USER_SIDEBAR", value });
  };
};

export const openOrdersDropdown = (value) => {
  return (dispatch) => {
    dispatch({ type: "OPEN_ORDERS_DROPDOWN", value });
  };
};

export const openMoyasarPaymentGateway = (value) => {
  return (dispatch) => {
    dispatch({ type: "OPEN_MOYASAR_PAYMENT_GATEWAY", value });
  };
};
export const addBranchField = (value) => {
  return (dispatch) => {
    dispatch({ type: "ADD_BRANCH_FIELD", value});
  };
};
export const setBranchField = (index, name, value) => {
  return (dispatch) => {
    dispatch({ type: "SET_BRANCH_FIELD", value:{index, name, value}});
  };
};
export const removeBranchField = (value) => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_BRANCH_FIELD", value});
  };
};
